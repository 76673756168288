<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <material-card
          color="primary"
          icon="mdi-account-outline"
        >
          <template #title>
            Картка адміністратора
          </template>

          <ButtonComeBack class="ml-3 mb-3" />

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    color="purple"
                    label="ПIБ"
                    v-model="user.full_name"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    color="purple"
                    label="Логiн"
                    v-model="user.login"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    color="purple"
                    label="Email"
                    v-model="user.email"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    color="purple"
                    label="Новий пароль"
                    v-model="newPassword"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-select 
                    v-model="userProjectsValue" 
                    label="Проекти" 
                    chips 
                    multiple 
                    cache-items
                    return-object
                    item-text="name"
                    item-value="name"
                    :items="projects" 
                    prepend-icon="mdi-format-list-bulleted-type">
                  </v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select 
                    v-model="userRolesValue" 
                    label="Групи доступу" 
                    chips 
                    multiple 
                    cache-items 
                    return-object
                    item-text="name"
                    item-value="name"
                    :items="roles" 
                    prepend-icon="mdi-format-list-bulleted-type">
                  </v-select>
                </v-col>

                <v-col cols="12" v-for="(item, index) in modules" :key="index">
                  <v-select v-model="item.value" 
                    :label="item.name" 
                    chips 
                    multiple
                    cache-items
                    return-object
                    item-text="name"
                    item-value="name"
                    :items="item.modules" 
                    prepend-icon="mdi-format-list-bulleted-type">
                  </v-select>
                </v-col>

                <v-col cols="12" class="text-right" >
                  <v-btn color="primary" min-width="150" @click="saveUser">
                    Зберегти
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </material-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <app-card class="mt-4 text-center">
          <v-img
            class="rounded-circle elevation-6 mt-n12 d-inline-block"
            src="https://eitrawmaterials.eu/wp-content/uploads/2016/09/person-icon.png"
            width="128"
          />

          <v-card-text class="text-center">
            <h6 class="text-h6 mb-2 text--secondary">
              ID: {{ user.id }}
            </h6>

            <h4 class="text-h4 mb-3 text--primary">
              {{ user.full_name }}
            </h4>
          </v-card-text>
        </app-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
    HTTP
} from '../http-common';
import { mapGetters, mapMutations, mapActions } from 'vuex'

  export default { 
    name: 'UserProfileView',
    data() {
      return {
        user: {},
        userProjectsValue: [],
        userRolesValue: [],
        userAdministrationValue: [],
        userOrdersValue: [],
        userPreordersValue: [],
        userActionsValue: [],
        newPassword: null,
        projects: []
      }
    },
    computed: {
      ...mapGetters(['snackbar', 'roles', 'modules']),
      moduleId() {
        let arr = [];

        this.modules.forEach(el => {
          if (el.value && el.value.length) {
            el.value.forEach(item => {
              arr.push(item.id)
            })
          }
        });

        return arr
      }
    },
    methods: {
      ...mapMutations(['setSnackbar']),
      ...mapActions(['getStatus', 'getModules']),
      getUser() {
        HTTP.post('/get-user', {
          'user_id': this.$route.params.id 
        })
          .then(({ data }) => {
            this.user = data.content || {};
            this.setProjects();
            this.setRoles();
            this.setModules();
          })
          // .catch((error) => {
          //   this.setSnackbar({ type: 'error', text: error.message });
          // })
      },
      getProjects() {
        HTTP.post('/get-projects')
          .then(response => {
            if (response.data && response.data.content) {
              this.projects = response.data.content
            }
          })
          .catch(error => {
              console.log(error);
          })
      },
      saveUser() {
        const dataUser = {
          'user_id': this.user.id || null,
          'email': this.user.email || null,
          'full_name': this.user.full_name || null,
          'login': this.user.login || null,
          'password': this.newPassword
        }

        const dataProjects = {
          'project_id': [ ...this.userProjectsValue.map(el => el.id) ],
          'user_id': this.user.id || null
        }

        const dataRoles = {
          'role_id': [ ...this.userRolesValue.map(el => el.id) ],
          'user_id': this.user.id || null
        }

        const dataModules = {
          "module_id": this.moduleId,
          'user_id': this.user.id || null,
        }

        Promise.all([
          HTTP.post('/update-user', dataUser),
          HTTP.post('/set-project-to-user', dataProjects),
          HTTP.post('/set-role-to-user', dataRoles),
          HTTP.post('/set-module-to-user', dataModules),
        ])
          .then(() => {
            this.setSnackbar({ type: 'success', text: 'Успiшно збережено' });
            this.$router.push('/users')
          })
          .catch((error) => {
            this.setSnackbar({ type: 'error', text: error.message });
          })
      },
      setProjects() {
        if (this.user.projects && this.user.projects.length) {
          this.userProjectsValue = this.user.projects;
        }
      },
      setRoles() {
        if (this.user.roles && this.user.roles.length) {
          this.userRolesValue = this.user.roles;
        }
      },
      setModules() {
        if (this.user.modules && this.user.modules.length) {
          this.user.modules.forEach(el => {
            this.modules.forEach(item => {
              if (item && el && item.name === el.name) {
                item.value = el.modules
              } 
            })
          });
        }
      }
    },
    mounted() {
      this.getProjects();
      this.getStatus();
      this.getModules();
      this.getUser();
    }
  }
</script>
